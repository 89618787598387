// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// Bootstrap
import "bootstrap"
import '../stylesheets/bootstrap4'

// Fontawesome
import "@fortawesome/fontawesome-free/js/all";
import '../stylesheets/fontawesome'

// Slick
import "slick-carousel/slick/slick.js"
import '../stylesheets/slick'

// PhotoSwipe
import "photoswipe/dist/photoswipe.js"
import "photoswipe/dist/photoswipe-ui-default.js"
import '../stylesheets/photoswipe'

// Readmore
import "./readmore.js"

require("packs/script")

import '../stylesheets/style'
