import $ from 'jquery';
import PhotoSwipe from 'photoswipe/dist/photoswipe.js';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';

$(function () {
  $('.js-delete-image').on('click', function() {
    $('[name="offer\[images_attachments_attributes\]\['+$(this).data('id')+'\]\[_destroy\]"]').prop('checked', true);
    $(this).parent('.js-image-container').fadeOut(300, function() { $(this).remove(); })
  })


  if ($('.offer .card-text, .single-offer .card-text').length > 0) {
    $('.offer .card-text, .single-offer .card-text').readmore({
      speed: 200,
      collapsedHeight: 75,
      heightMargin: 25,
      moreLink: '<a href="#" class="readmore"><i class="fas fa-arrow-circle-down"></i></a>',
      lessLink: '<a href="#" class="readmore"><i class="fas fa-arrow-circle-up"></i></a>'
    });
  }

  $('[data-toggle="tooltip"]').tooltip();


  // PhotoSwipe
  var pswpElement = document.querySelectorAll('.pswp')[0];
  var pswItems = {};

  $('.psw-item').each(function() {
    if (!Array.isArray(pswItems[$(this).data('id')])) {
      pswItems[$(this).data('id')] = [];
    }

    pswItems[$(this).data('id')].push({
      src: $(this).data('orig'),
      w: $(this).data('width'),
      h: $(this).data('height')
    });
  });

  $('.psw-item-show').click(function(e) {
    e.preventDefault();
    var index = $(this).data('index');

    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, pswItems[$(this).data('id')], {index: index});
    gallery.init();
  });


  // Slick must be initializes after PhotoSwipe
  $('.js-gallery').slick({
    infinite: true,
    dots: true,
    arrows: false
  });
});
